<template>
  <div v-if="loading" class="base_layer notification">
    <Loading style="margin-top: 12rem"></Loading>
  </div>

  <div v-else class="base_layer wizard">
    <Header :text="t('study_field.choose_studied_field')" :destination-component="'toStudyType'" />

    <div class="cards-wrapper ps">
      <div class="card" v-for="field in filteredStudyFields" :key="field.id">
        <button class="want_ticket" @click="clickManager(field.id)">
          {{ translateStudyType(field.studyType) + cutText(field.name) }}
        </button>
      </div>
    </div>

    <div class="footer">
      <go-cancel-arrow />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useStore } from 'vuex';
import emitter from '@/config/emitter';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

import Header from "@/components/Header.vue";
import Loading from "@/components/Loading.vue";
import GoCancelArrow from "@/components/GoCancelArrow.vue";
import { restoreTimeMilis } from '@/store/config';

const store = useStore();
const { t, locale } = useI18n();

const loading = ref(false);
const timeout = ref(null);
const studyFields = ref([]);
const studyFieldType = ref(null);

const getStudyFieldType = computed(() => store.getters['getStudyFieldType']);
const getStudyCategory = computed(() => store.getters['getStudyCategory']);
const getStudyRestrictions = computed(() => store.getters['getStudyRestrictions']);
const getAssistantBalance = computed(() => store.getters['getAssistantBalance']);
const didLoadedAssistantBalance = computed(() => store.getters['didLoadedAssistantBalance']);

const filteredStudyFields = computed(() => {
  return studyFields.value.filter(f => f.type === studyFieldType.value);
});

const setStudyFieldTypeFromStorage = () => {
  studyFieldType.value = getStudyFieldType.value;
};

const translateStudyType = (status) => {
  switch (status) {
    case 'Master':
      return t('study_field.Master') + " - ";
    case 'Bachelor':
      return t('study_field.Bachelor') + " - ";
    case 'Other':
      return "";
  }
};

const cutText = (text) => {
  return text.length > 40 ? text.slice(0, 38) + '...' : text;
};

const clickManager = async (id) => {
  loading.value = true;

  try {
    await store.dispatch('fetchAssistantBalance', id);

    if (didLoadedAssistantBalance.value) {
      if (getAssistantBalance.value.length > 0) {
        loading.value = false;
        if (studyFieldType.value === "other_type") {
          setStudyFieldIdMeetings(id);
        } else {
          setSFieldId(id);
        }
      } else {
        loading.value = false;
        emitter.emit('toNoAssistant');
      }
    }
  } catch {
    emitter.emit('toError');
  }
};

const setSFieldId = (id) => {
  store.dispatch('queueItemTo/setFieldId', id);
  emitter.emit('toStudyCategory');
};

const setStudyFieldIdMeetings = (id) => {
  store.dispatch('queueItemTo/setStudyFieldIdMeeting', id);
  setSFieldId(id);
  emitter.emit('toMeeting');
};

const findIfRestriction = (array) => {
  let restrictionAssIds = cleanRestrictions();
  return array.filter(item => !restrictionAssIds.includes(item));
};

const cleanRestrictions = () => {
  let restrictions = getStudyRestrictions.value;
  let restrictedIds = [];

  if (restrictions.length !== 0) {
    for (let item of restrictions) {
      if (moment().isAfter(item.timeFrom) && moment().isBefore(item.timeTo) && item.closedHoursReason.type === 'person') {
        restrictedIds.push(item.assistantId);
      }
    }
  }

  return [...new Set(restrictedIds)];
};

const setCorrectLanguage = () => {
  let items = store.getters['getStudyFieldsByTargetLanguageSortedById'](locale.value);
  studyFields.value = items.map(item => ({
    type: item.type,
    id: item.id,
    assistantsIds: findIfRestriction(item.assistantsIds),
    name: locale.value === 'en' ? item.name_en : item.name,
    studyType: item.studyType,
  }));
};

// Set up a timeout on mount and clear it on unmount
onMounted(() => {
  setStudyFieldTypeFromStorage();
  setCorrectLanguage();

  timeout.value = setTimeout(() => {
    emitter.emit('Start');
    store.dispatch('queueItemTo/restoreState');
  }, restoreTimeMilis.restoreTimeTicket);

});

onBeforeUnmount(() => {
  clearTimeout(timeout.value);
});

</script>

<style scoped>

</style>
