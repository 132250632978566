<template>
  <div class="base_layer wizard">
    <Header :destination-component="'Start'" :text="t('study_type.choose_type_of_study')" @click="handleBackButton"/>
    <div class="cards-wrapper">
      <div class="card">
        <button :disabled="setDisabledIfNoChildren('daily_type')" class="want_ticket"
                @click="setStudyFieldType('daily_type')">
          {{ t('study_type.daily') }}
        </button>
      </div>
      <div class="card">
        <button :disabled="setDisabledIfNoChildren('weekly_type')" class="want_ticket"
                @click="setStudyFieldType('weekly_type')">
          {{ t('study_type.weekly') }}
        </button>
      </div>
      <div class="card">
        <button :disabled="setDisabledIfNoChildren('other_type')" class="want_ticket"
                @click="setStudyFieldType('other_type')">
          {{ t('study_type.other') }}
        </button>
      </div>
    </div>
    <div class="footer">
      <go-cancel-arrow></go-cancel-arrow>
    </div>
  </div>
</template>

<script setup>

import {ref, onMounted, onBeforeUnmount, computed} from 'vue';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import Header from "@/components/Header.vue";
import GoCancelArrow from "@/components/GoCancelArrow.vue";
import {restoreTimeMilis} from '@/store/config';
import emitter from '@/config/emitter';

const store = useStore();
const {t, locale} = useI18n();
const timeout = ref(null);

// Set up a timeout on mount and clear it on unmount
onMounted(() => {
  timeout.value = setTimeout(() => emitter.emit('Start'), restoreTimeMilis.restoreTime);
});

onBeforeUnmount(() => {
  clearTimeout(timeout.value);
});

const getStudyFieldsByTypeAndTargetLanguage = computed(() =>
  store.getters.getStudyFieldsByTypeAndTargetLanguage
);

const setStudyFieldTypeStudy = (type) => {
  store.dispatch('setStudyFieldTypeStudy', type);
};

const setDisabledIfNoChildren = (type) => {
  return getStudyFieldsByTypeAndTargetLanguage.value(type, locale.value).length === 0;
};

const restoreState = () => {
  store.dispatch('queueItemTo/restoreState');
};

const handleBackButton = () => {
  restoreState();
};

// Method to set the study field type and navigate to the next step
const setStudyFieldType = (type) => {
  setStudyFieldTypeStudy(type);
  emitter.emit('toStudyField');
};

</script>

<style scoped>

</style>
