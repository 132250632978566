<template>
  <div class="base_layer wizard">
    <Header :text="t('study_category.select_category_demands')" :destination-component="getDestinationComponent" />
    <div class="cards-wrapper ps" >
      <div class="card" v-for="category in studyCategory" :key="category.id">
        <button
          :disabled="retStudyRequirementsTest(category.id)"
          class="want_ticket"
          @click="clickManager(category.id)"
        >
          {{ cutText(category.name) }}
        </button>
      </div>
    </div>

    <div class="footer">
      <go-cancel-arrow />
    </div>

  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount, computed, nextTick} from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Header from "@/components/Header.vue";
import GoCancelArrow from "@/components/GoCancelArrow.vue";
import { restoreTimeMilis } from '@/store/config';
import emitter from "@/config/emitter";

const store = useStore();
const { t, locale } = useI18n();

const studyCategory = ref([]);
const timeout = ref(null);

onMounted(() => {

  setCorrectLanguage();

  timeout.value = setTimeout(() => {
    emitter.emit('Start');
  }, restoreTimeMilis.restoreTime);
});

onBeforeUnmount(() => {
  clearTimeout(timeout.value);
});

const setCorrectLanguage = () => {
  const items = store.getters.getStudyCategory;
  studyCategory.value = items.map(item => ({
    type: item.type,
    id: item.id,
    code: item.code,
    name: locale.value === 'en' ? item.name_en : item.name,
  }));
};

const getDestinationComponent = computed(() => {
  return store.getters['queueItemTo/getStudyRequirementLength'] === 0 ? 'toStudyField' : 'toStudyRequirementRecap';
});

const retStudyRequirementsTest = (code) => {
  const allRequirements = store.getters.getStudyRequirement;
  const pickedRequirements = store.getters['queueItemTo/getStudyRequirementIds'];

  const codeRequirements = allRequirements.filter(
    (p) => p.studyProblemsCategoryId === code && p.isShown === true
  );

  const trueRequirements = codeRequirements.filter(
    (item) => !pickedRequirements.includes(item.id)
  );

  return trueRequirements.length === 0;
};

const cutText = (text) => {
  return text.length > 40 ? text.slice(0, 38) + '...' : text;
};

const clickManager = (studyCategoryId) => {
    store.dispatch('queueItemTo/setStudyCategoryId', studyCategoryId)
    emitter.emit('toStudyRequirement');
};

</script>

<style scoped>

</style>
