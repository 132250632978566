<template>
  <div class="base_layer notification">
    <div id="basic">
      <div id="bye">
        <span>{{ t('closed.unfortunately') }}</span>
      </div>
      <div id="hope">
        <span>{{ t('closed.description') }}</span>
        <div v-if="currentDayStudyHours.openTime !== null">
          <span>{{ t('closed.opening_hours') }}</span>
          <p>
            <span>{{ currentDayStudyHours.openTime }} – </span>
            <span>{{ currentDayStudyHours.closeTime }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

const store = useStore();
const { t, locale } = useI18n();
const dayNumber = moment().day().toString();
let langInterval = null;
const currentDayStudyHours = ref({
  openTime: null,
  closeTime: null
});

// Set the current day's study hours on mount
onMounted(() => {
  currentDayStudyHours.value = store.getters.getStudyOpenHour(dayNumber);

  // Change locale every 15 seconds
  langInterval = setInterval(() => {
    changeLocale();
  }, 15000);
});

onBeforeUnmount(() => {
  clearInterval(langInterval);
});

const changeLocale = () => {
  locale.value = locale.value === 'en' ? 'cz' : 'en';
};
</script>

<style scoped>
#bye {
  color: white;
  font-size: 3em;
  font-weight: bolder;
}

#hope {
  color: white;
  font-weight: bolder;
  font-size: 2em;
  margin-top: 20px;
}
</style>
