<template>
  <div class="base_layer notification">
    <div id="basic">
      <div id="bye">
        <span>{{ t('error.unfortunately') }}</span>
      </div>
      <div id="hope">
        <span>{{ t('error.description') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { restoreTimeMilis } from '@/store/config';
import emitter from "@/config/emitter";

const store = useStore();
const { t, locale } = useI18n();

const langInterval = ref(null);
const timeout = ref(null);

const changeLocale = () => {
  locale.value = locale.value === 'en' ? 'cz' : 'en';
};

onMounted(() => {
  langInterval.value = setInterval(() => {
    changeLocale();
  }, restoreTimeMilis.changeLocaleTime);

  timeout.value = setTimeout(() => {

    if (store.getters.isClosed) emitter.emit('toClosed');
    else if (store.getters.isPaused) emitter.emit('toPaused');
    else {
      store.dispatch('dataLoader/reloadAllData');
      store.dispatch('queueItemTo/restoreState');
      emitter.emit('Start');
    }

  }, restoreTimeMilis.restoreTimeError);
});

onBeforeUnmount(() => {
  clearInterval(langInterval.value);
  clearTimeout(timeout.value);
});
</script>

<style scoped>
    #bye {
        color: white;
        font-size: 3em;
        font-weight: bolder;
    }

    #hope {
        color: white;
        font-weight: bolder;
        font-size: 2em;
        margin-top: 20px;
    }

</style>
