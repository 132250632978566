<template>
  <div class="base_layer wizard">
    <Header :destination-component="'toStudyCategory'" :text="t('study_requirement.select_concrete_demand')"
            @click="handleBackButton"/>
    <div class="cards-wrapper ps">
      <div v-for="requirement in requirements" :key="requirement.id" class="card">
        <button class="want_ticket want_ticket_wide" @click="clickManager(requirement.id)">
          {{ cutText(locale.value === 'en' ? requirement.name_en : requirement.name) }}
        </button>
      </div>
    </div>
    <div class="footer">
      <go-cancel-arrow/>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import Header from "@/components/Header.vue";
import GoCancelArrow from "@/components/GoCancelArrow.vue";
import {restoreTimeMilis} from '@/store/config';
import emitter from "@/config/emitter";

const store = useStore();
const {t, locale} = useI18n();

const timeout = ref(null);
const interval = ref(null);
const study_category_code = ref(null);
const requirements = ref([]);

onMounted(() => {
  setStudyCategoryCode();
  requirements.value = retStudyRequirementsTest();

  interval.value = setInterval(() => {
    requirements.value = retStudyRequirementsTest();
  }, 5000);

  if (requirements.value.length === 0) {
    emitter.emit('toStudyCategory');
  }

  timeout.value = setTimeout(() => {
    emitter.emit('Start');
  }, restoreTimeMilis.restoreTime);

});

onBeforeUnmount(() => {
  clearTimeout(timeout.value);
  clearInterval(interval.value);
});

const getStudyRequirement = computed(() => store.getters.getStudyRequirement);
const getStudyRequirementIds = computed(() => store.getters['queueItemTo/getStudyRequirementIds']);

const retStudyRequirementsTest = () => {
  let trueRequirements = [];
  let codeRequirements = [];

  const code = study_category_code.value;
  const allRequirements = getStudyRequirement.value;
  const pickedRequirements = getStudyRequirementIds.value;

  for (let p of allRequirements) {
    if (p.studyProblemsCategoryId === parseInt(code) && p.isShown === true) {
      codeRequirements.push(p);
    }
  }

  trueRequirements = codeRequirements.filter((item) => !pickedRequirements.includes(item.id));

  return trueRequirements;
};

const setStudyCategoryCode = () => {
  study_category_code.value = store.getters['queueItemTo/getStudyCategoryId'];
};

const clickManager = (requirementId) => {
  nextTick(() => {
    store.dispatch('queueItemTo/addRequirementId', requirementId);
    emitter.emit('toStudyRequirementRecap');
  })
};

const cutText = (text) => {
  return text.length > 85 ? text.slice(0, 82) + '...' : text;
};

const handleBackButton = () => {
  store.dispatch('queueItemTo/resetStudyCategoryId');
};

</script>

<style scoped>

.want_ticket_wide {
  min-width: 1000px;
}

</style>
