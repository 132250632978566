<template>
  <div class="base_layer notification">
    <v-col class="touch_panel_container">
      <missing-data-overlay v-if="!isEverythingFilled"/>

      <v-row class="header_row">
        <div class="row_2">
          <div>
            <button class="language_button"
                    :class="currentFlagClass"
                    @click="changeLocale"></button>
            <div class="info" style="width: auto">
              {{ languageText }}
            </div>
          </div>
        </div>
        <div id="row_2">
          <h1 id="top_row_header">{{ t('start.waiting_for_study_dep') }}</h1>
        </div>
        <div class="row_3">
          <digital-clock class="clock"/>
        </div>
      </v-row>

      <div class="middle_row">
        <button id="want_ticket" @click="toStudyType"> {{ t('start.ticket') }}</button>
        <p class="info">{{ t('start.if_you_didnt_get') }}</p>
      </div>
      <div class="mm_logo" :class="currentLogo">
      </div>
    </v-col>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import DigitalClock from "@/components/Clock.vue";
import MissingDataOverlay from '../components/MissingDataOverlay.vue';
import emitter from '@/config/emitter';

const store = useStore();
const { t, locale } = useI18n();

onMounted(() => {
  locale.value = 'cz';
  document.title = 'Touch Panel MyMendelu';
});

const currentFlagClass = computed(() => {
  return locale.value === 'en' ? 'cz_flag' : 'en_flag';
});

const languageText = computed(() => {
  return locale.value === 'en' ? 'Změnit jazyk' : 'Change language';
});

const currentLogo = computed(() => {
  return locale.value === 'en' ? 'en_mm_logo' : 'cz_mm_logo';
});

const isEverythingFilled = computed(() => {
  return store.getters.areStudyOpenHoursFilled &&
    store.getters.areStudyProblemsItemsFilled &&
    store.getters.areStudyProblemsCategoryFilled &&
    store.getters.areAssistantsFilled &&
    store.getters.areStudyFieldsFilled;
});

const changeLocale = () => {
  locale.value = locale.value === 'en' ? 'cz' : 'en';
};


const toStudyType = () => {
  emitter.emit('toStudyType');
};

</script>

<style scoped>
.base_layer {
  background-color: white;
  background-image: url("../assets/images/placeholder-left.png"), url("../assets/images/placeholder-right.png");
  background-position: left bottom, 100% 70%;
  background-size: auto 40vw, auto 40vw;
  background-repeat: no-repeat;
}

.header_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.row_3 {
  width: 14vw;
  padding: 5vh 3vw;
}

#top_row_header {
  padding-left: 15%;
  min-width: 55%;
  padding-right: 15%;
  font-size: 3vw;
  color: black;
  margin-top: 3%;
}

.cz_flag {
  background-image: url("../assets/images/CZflag_square.png");
  background-size: 130px 130px;
  background-repeat: no-repeat;
}

.en_flag {
  background-image: url("../assets/images/UKflag_square.png");
  background-size: 130px 130px;
  background-repeat: no-repeat;
}

.cz_mm_logo {
  background: url("../assets/images/cz_mm_logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.en_mm_logo {
  background: url("../assets/images/en_mm_logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.mm_logo {
  position: absolute;
  bottom: 3%;
  right: 1%;
  width: 15%;
  height: 5%
}

.touch_panel_container {
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%
}

.language_button {
  width: 128px;
  height: 128px;
  border: none;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  padding: 0;
  display: block;
  margin: 3vh 3vw;
  margin-bottom: 1vw !important;
  -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
}

.language_button:active {
  background-color: white;
}

.middle_row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 38vh;
}

#want_ticket {
  display: block;
  position: relative;
  width: 470px;
  height: 100px;
  background: #7ABE12;
  border-radius: 40px;
  color: white;
  font-size: xx-large;
  border: none;
  -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  margin-bottom: 20px;
}

#want_ticket:active {
  background-color: white;
  color: black;
}

.info {
  margin-top: 0px;
  width: 470px;
  font-size: large;
  font-weight: bold;
  color: grey;
  text-align: center;
  background: rgba(255, 255, 255, 0.767);
}
</style>
