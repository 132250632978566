<template>
    <div class="wrapper">
        <component :is="component"></component>
    </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

import Start from "./Start.vue";
import StudyType from "./StudyType.vue";
import StudyField from "./StudyField.vue";
import StudyCategory from "./StudyCategory.vue";
import StudyRequirement from "./StudyRequirement.vue";
import StudyRequirementRecap from "./StudyRequirementRecap.vue";
import Ticket from "./Ticket.vue";
import End from "./End.vue";
import Closed from "./Closed.vue";
import ClosedPause from "./ClosedPause.vue";
import ClosedDueTo from "./ClosedDueTo.vue";
import StudyRequirementRecapMeetings from "./StudyRequirementRecapMeetings.vue";
import Error from "./Error.vue";
import NoAssistant from "./NoAssistant.vue";
import StudentIdentification from "./StudentIdentification";

import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

import {fullURL, restoreTimeMilis} from '@/store/config';

import emitter from '@/config/emitter';

export default {
    name: "CheckInMaster",

    components: {
        Start,
        StudyType,
        StudyField,
        StudyCategory,
        StudyRequirement,
        StudyRequirementRecap,
        Ticket,
        End,
        Closed,
        ClosedPause,
        ClosedDueTo,
        StudyRequirementRecapMeetings,
        Error,
        NoAssistant,
        StudentIdentification
    },

    setup() {
      const store = useStore();
      const component = ref(null);
      const connected = ref(false);
      const stompClient = ref(null);
      const socket = ref(null);
      const isLoading = ref(null);
      const toCloseOrPauseInterval = ref(null);
      const toOpenInterval = ref(null);

      const setCurrentComponentFromStorage = () => {
        component.value = store.getters.getCallInCurrentComponent;
      };

      const connect = () => {
        socket.value = new SockJS(fullURL.url + "socket");
        stompClient.value = Stomp.over(socket.value);

        stompClient.value.debug = () => {};

        stompClient.value.connect({}, frame => {
          connected.value = true;
          stompClient.value.subscribe(`/study-department/touch-panel`, message => {
            isLoading.value = true;
            let item = JSON.parse(message.body);

            store.dispatch('setStudyFields', item.studyFields);
            store.dispatch('setStudyCategory', item.studyProblemsCategories);
            store.dispatch('setStudyRequirement', item.studyProblemsItems);
            store.dispatch('setAssistants', item.assistants);
            store.dispatch('setStudyOpenHours', item.studyOpenHours);

            isLoading.value = false;
          });

          send();
        }, error => {
          console.error('Socket connection error:', error);
          console.info('Reconnecting websocket in 10 seconds.');

          connected.value = false;

          setTimeout(() => connect(), 10000);
        });
      };

      const send = () => {
        if (stompClient.value && stompClient.value.connected) {
          stompClient.value.send("/app/study-department/request-touch-panel", null, {});
        }
      };

      const disconnect = () => {
        if (stompClient.value) {
          stompClient.value.disconnect();
        }
        connected.value = false;
      };

      const changePanelState = () => {
        if (store.getters.isClosed) {
          emitter.emit('toClosed');
        } else if (store.getters.isPaused) {
          emitter.emit('toPaused');
        } else {
          emitter.emit('Start');
          clearInterval(toOpenInterval.value);
        }
      };

      const closePanel = () => {
        if (store.getters.isClosed) {
          emitter.emit('toClosed');
          clearInterval(toCloseOrPauseInterval.value);
        }
      };

      const pausePanel = () => {
        if (store.getters.isPaused) {
          emitter.emit('toPaused');
          clearInterval(toCloseOrPauseInterval.value);
        }
      };

      const goToRestriction = async () => {
        await store.dispatch('fetchStudyRestrictions');
        let preModel = store.getters.getStudyRestrictions.find(function (model) {
          return moment().isAfter(moment(model.timeFrom)) && moment().isBefore(moment(model.timeTo)) && model.closedHoursReason.type === 'department';
        });

        if (preModel !== undefined) {
          if (moment().isAfter(moment(preModel.timeFrom)) && moment().isBefore(moment(preModel.timeTo))) {
            emitter.emit('toClosedDueTo');
            clearInterval(toCloseOrPauseInterval.value);
          }
        }
      }

      onMounted(() => {
        connect();
        setCurrentComponentFromStorage();

        emitter.on('Start', () => {
          component.value = 'Start';
          store.dispatch('queueItemTo/restoreState');
        });

        emitter.on('toStudyType', () => {
          component.value = 'StudyType';
        });

        emitter.on('toStudyField', () => {
          component.value = 'StudyField';
        });

        emitter.on('toStudyCategory', () => {
          component.value = 'StudyCategory';
        });

        emitter.on('toStudyRequirement', () => {
          component.value = 'StudyRequirement';
        });

        emitter.on('toStudyRequirementRecap', () => {
          component.value = 'StudyRequirementRecap';
        });

        emitter.on('toTicket', () => {
          component.value = 'Ticket';
        });

        emitter.on('toEnd', () => {
          component.value = 'End';
        });

        emitter.on('toClosed', () => {
          component.value = 'Closed';
        });

        emitter.on('toPaused', () => {
          component.value = 'ClosedPause';
        });

        emitter.on('toClosedDueTo', () => {
          component.value = 'ClosedDueTo';
        });

        emitter.on('toMeeting', () => {
          component.value = 'StudyRequirementRecapMeetings';
        });

        emitter.on('toError', () => {
          component.value = 'Error';
        });

        emitter.on('toNoAssistant', () => {
          component.value = 'NoAssistant';
        });

        emitter.on('toIdentification', () => {
          component.value = 'StudentIdentification';
        });
      });

      onBeforeUnmount(() => {
        disconnect();
        emitter.all.clear(); // Clear all listeners
      });

      watch(component, async (newComponent) => {
        if (newComponent === 'End') {
          await send();
          closePanel();
          pausePanel();
          goToRestriction();
        }

        if (newComponent === 'Start') {
          toCloseOrPauseInterval.value = setInterval(async () => {
            await send();
            closePanel();
            pausePanel();
            goToRestriction();
          }, 5000);
        }

        if (newComponent === 'Closed' || newComponent === 'ClosedPause') {
          toOpenInterval.value = setInterval(async () => {
            await send();
            changePanelState();
          }, 20000);
        }

        if (newComponent !== 'Closed' && newComponent !== 'Start' && newComponent !== 'ClosedPause') {
          clearInterval(toOpenInterval.value);
          clearInterval(toCloseOrPauseInterval.value);
        }
      });

      return {
        component
      };
    }
}


</script>

<style scoped>

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

</style>
