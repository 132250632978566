<template>
  <div class="base_layer vld-parent">
    <Loading
      :active.sync="isLoadingData"
      :height="125"
      :is-full-page="false"
      :width="125"
      backgroundColor="#6D717A"
      color="#7ABE12"
    ></Loading>

    <Header :destination-component="'toStudyField'" :text="t('study_requirement_recap.chosen_demands')"/>

    <div class="ps">
      <div ref="vs">
        <div class="in_middle">
          <span v-if="locale === 'en'" class="want_ticket_wide">{{ returnStudyField.name_en }}</span>
          <span v-else class="want_ticket_wide">{{ cutText(returnStudyField.name) }}</span>
        </div>
      </div>
    </div>

    <div class="row_bottom">
      <div class="generate_ticket_button">
        <button id="generate_ticket" @click="goToIdentification">
          {{ t('study_requirement_recap.generate') }}
        </button>
      </div>
      <div class="generate_ticket_button_right">
        <go-cancel-arrow></go-cancel-arrow>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount, computed} from 'vue';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import moment from 'moment';

import DigitalClock from "@/components/Clock.vue";
import GoCancelArrow from "@/components/GoCancelArrow.vue";
import Loading from 'vue3-loading-overlay';

import {restoreTimeMilis} from '@/store/config';
import emitter from "@/config/emitter";
import Header from "@/components/Header.vue";

const store = useStore();
const {t, locale} = useI18n();

const isLoadingData = ref(false);

const timeout = ref(null);

const returnStudyField = computed(() =>
  store.getters['getSingleStudyField'](store.getters['queueItemTo/getStudyFieldIdMeeting'])
);

const toStudyField = () => {
  emitter.emit('toStudyField');
};

const goToIdentification = () => {
  isLoadingData.value = true;
  emitter.emit('toIdentification');
};

const cutText = (text) => {
  return text.length > 85 ? text.slice(0, 82) + '...' : text;
};

// Set up a timeout on mount and clear it on unmount
onMounted(() => {
  timeout.value = setTimeout(() => {
    emitter.emit('Start');
    store.dispatch('queueItemTo/restoreState');
  }, restoreTimeMilis.restoreTime);
});

onBeforeUnmount(() => {
  clearTimeout(timeout.value);
});
</script>

<style scoped>
.base_layer {
  justify-content: space-between;
  background-color: #6D717A;
}

.in_middle {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.want_ticket_wide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 950px;
  height: 80px;
  background-color: var(--base-color);
  border-radius: 40px;
  color: white;
  font-size: x-large;
  border: none;
  -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  font-weight: bolder;
  margin-bottom: 1rem;
}

.ps {
  width: 100%;
  display: block;
}

#generate_ticket {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  height: 100px;
  background: #7ABE12;
  border-radius: 40px;
  color: white;
  font-size: x-large;
  border: none;
  -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  font-weight: bolder;
  float: right;
  margin-right: 40px;
  margin-top: 30px;
}

#generate_ticket:active {
  background-color: white;
  color: black;
  transform: translateY(1px);
  filter: saturate(150%);
}

#generate_ticket:disabled {
  background-color: #a9a9ad;
  color: #727272;
}

.generate_ticket_button {
  float: left;
  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
}

.generate_ticket_button_right {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.5em;
  display: flex;
  justify-content: center;
}

.row_bottom {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  padding: 0.5em;
  position: relative;
}

</style>
