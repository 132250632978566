<template>
  <time class="clock">
    <span class="clock__hour">{{ hours }}</span>
    <span>:</span>
    <span class="clock__minutes">{{ minutes }}</span>
  </time>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const padZero = (number) => (number < 10 ? '0' + number : number);
const getDate = () => new Date();
const getSeconds = () => padZero(getDate().getSeconds());
const getMinutes = () => padZero(getDate().getMinutes());
const getHour = (twelveHour) => {
  let hours = getDate().getHours();
  if (twelveHour && hours > 12) {
    hours -= 12;
  }
  return padZero(hours);
};
const getAmPm = () => (getDate().getHours() > 12 ? 'pm' : 'am');

const props = defineProps({
  blink: Boolean,
  displaySeconds: Boolean,
  twelveHour: Boolean
});

const minutes = ref(getMinutes());
const hours = ref(getHour(props.twelveHour));
const seconds = ref(getSeconds());
const amPm = ref(getAmPm());

let ticker = null;

// Update time every second
onMounted(() => {
  ticker = setInterval(() => {
    minutes.value = getMinutes();
    hours.value = getHour(props.twelveHour);
    seconds.value = getSeconds();
  }, 1000);
});

onBeforeUnmount(() => {
  clearInterval(ticker);
});
</script>

<style scoped>
.clock {
  font-size: xx-large;
  font-weight: bold;
  color: black;
}
</style>
