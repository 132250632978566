<template>
  <div class="text-center mt-5 mb-5">
    <v-progress-circular
      :size="200"
      :width="15"
      color="#76be1a"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script setup>
</script>

<style scoped>
</style>
