import { createStore } from 'vuex';
import user from './modules/User'
import studyField from "./modules/StudyField";
import studyCategory from "./modules/StudyCategory"
import studyRequirement from "./modules/StudyRequirement"
import queueItemTo from "./modules/QueueItemTo";
import assistant from "./modules/Assistant";
import dataLoader from "./modules/DataLoader";
import siteData from "./modules/SiteData";
import StudyOpenHours from "./modules/StudyOpenHours";
import StudyRestrictions from "./modules/StudyRestrictions";
import ClosedHoursReasons from "./modules/ClosedHoursReasons";
import AssistantBalance from "./modules/AssistantBalance";

export default createStore({
    modules: {
        user,
        studyField,
        studyCategory,
        studyRequirement,
        queueItemTo,
        assistant,
        dataLoader,
        siteData,
        StudyOpenHours,
        StudyRestrictions,
        ClosedHoursReasons,
        AssistantBalance
    }
});
