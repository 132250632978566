<template>
  <div class="header">
    <div class="back-arrow">
      <a href="#" >
        <img v-if="destinationComponent" id="back_button" src="@/assets/images/arrow.png" alt="arrow" @click="goBack" />
      </a>
    </div>
    <div class="heading">
      <h1 id="top_row_header">{{ text }}</h1>
    </div>
    <digital-clock></digital-clock>
  </div>
</template>

<script setup>
import {defineProps} from 'vue';
import DigitalClock from "@/components/Clock.vue";
import emitter from "@/config/emitter";

const props = defineProps({
  text: String,
  destinationComponent: String
});

const goBack = () => {
  emitter.emit(props.destinationComponent);
}

</script>

<style scoped>
    .header {
        width: 100%;
        height: 8vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .header > div {
        padding: 0 3vw;
    }

    .back-arrow {
        width: 14vw;
    }

    #back_button {
        width: 100%;
    }

    .clock {
      width: 14vw;
      color: white;
    }

    .heading {
        color: white;
    }

</style>
