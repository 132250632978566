<template>
  <div class="base_layer notification">
    <div id="basic">
      <div id="bye">
        <span>{{ t('closed.unfortunately') }}</span>
      </div>
      <div class="hope">
        <span>{{ t('closed.description') }}</span>
      </div>
      <div class="hope">
        <span v-if="locale.value === 'en'">Reason: {{ model.reasonEng }}</span>
        <span v-else>Důvod: {{ model.reasonCz }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount, computed} from 'vue';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import moment from 'moment';
import emitter from "@/config/emitter";

const store = useStore();
const {t, locale} = useI18n();

// State for model and intervals
const model = ref({
  timeFrom: null,
  timeTo: null,
  reasonEng: null,
  reasonCz: null,
  wantedDay: null,
  type: null,
});

let langInterval = null;
let routeInterval = null;

const getStudyRestrictions = computed(() => store.getters.getStudyRestrictions);

// Method to load model based on current restrictions
const loadModel = () => {
  const preModel = getStudyRestrictions.value.find(model => {
    return moment().isAfter(moment(model.timeFrom)) && moment().isBefore(moment(model.timeTo)) && model.closedHoursReason.type === 'department';
  });

  if (!preModel) {
    emitter.emit('Start');
  } else {
    if (moment().isAfter(moment(preModel.timeFrom)) || moment().isBefore(moment(preModel.timeTo))) {
      model.value.reasonCz = preModel.closedHoursReason.reason;
      model.value.reasonEng = preModel.closedHoursReason.reason_en;
    } else {
      emitter.emit('Start');
    }
  }
};

const changeLocale = () => {
  locale.value = locale.value === 'en' ? 'cz' : 'en';
};

// Fetch restrictions and load model on component creation
onMounted(async () => {
  await store.dispatch('fetchStudyRestrictions');
  loadModel();

  // Set up language change interval
  langInterval = setInterval(() => {
    changeLocale();
  }, 15000);

  // Set up route interval to refresh data
  routeInterval = setInterval(async () => {
    await store.dispatch('fetchStudyRestrictions');
    loadModel();
  }, 30000);
});

// Clear intervals on component unmount
onBeforeUnmount(() => {
  clearInterval(langInterval);
  clearInterval(routeInterval);
});
</script>

<style scoped>
#bye {
  color: white;
  font-size: 3em;
  font-weight: bolder;
}

.hope {
  color: white;
  font-weight: bolder;
  font-size: 2em;
  margin-top: 20px;
}
</style>
