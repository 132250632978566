import { createApp } from 'vue';
import App from './App.vue';

import VueQrcode from '@chenfengyuan/vue-qrcode';
import { createRouter, createWebHistory } from 'vue-router';

import store from './store/Store.js'
import routes from "@/router/router";
import vuetify from './config/vuetify';
import i18n from "@/config/i18n";

const app = createApp(App);

app.config.globalProperties.$localStorage = localStorage;
localStorage.debug = "axios";

// Registering global components and plugins
app.component(VueQrcode.name, VueQrcode);

const router = createRouter({
    history: createWebHistory(),
    routes
});

app.use(router);
app.use(store);
app.use(i18n);
app.use(vuetify);

// Mount the app to the DOM
app.mount('#app');
