<template>
  <div class="base_layer notification" @click="close">
    <div id="basic">
      <div id="bye">
        <span>{{ t('end.goodbye') }}</span>
      </div>
      <div id="hope">
        <span>{{ t('end.we_hope') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { restoreTimeMilis } from '@/store/config';
import emitter from "@/config/emitter";

const store = useStore();
const { t } = useI18n();

const timeout = ref(null);

const close = () => {
  clearTimeout(timeout.value);
  emitter.emit('Start');
};

onMounted(() => {
  timeout.value = setTimeout(() => {
    close();
  }, restoreTimeMilis.restoreTimeEnd);
});

onBeforeUnmount(() => {
  clearTimeout(timeout.value);
});
</script>

<style scoped>
#bye {
  color: white;
  font-size: 2.5em;
  font-weight: bolder;
}

#hope {
  color: white;
  font-weight: bolder;
  font-size: x-large;
  margin-top: 20px;
}
</style>
