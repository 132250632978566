<template>
  <div class="base_layer notification">
    <div id="basic">
      <div id="bye">
        <span>{{ t('closed_pause.unfortunately') }}</span>
      </div>
      <div id="hope">
        <span>{{ t('closed_pause.description') }}</span>
        <div v-if="currentDayStudyHours.openTime !== null">
          <span>{{ t('closed_pause.pause_ends_at') }}</span>
          <span>{{ currentDayStudyHours.pauseEnd }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

const store = useStore();
const { t, locale } = useI18n();
let langInterval = null;
const currentDayStudyHours = ref({
  openTime: null,
  pauseEnd: null
});

// On mounted, set the current day's study hours and start the language change interval
onMounted(() => {
  currentDayStudyHours.value = store.getters.getStudyOpenHour(moment().day().toString());

  langInterval = setInterval(() => {
    changeLocale();
  }, 15000);
});

onBeforeUnmount(() => {
  clearInterval(langInterval);
});

const changeLocale = () => {
  locale.value = locale.value === 'en' ? 'cz' : 'en';
};
</script>

<style scoped>
.base_layer {
  background-color: var(--base-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

#bye {
  color: white;
  font-size: 3em;
  font-weight: bolder;
}

#hope {
  color: white;
  font-weight: bolder;
  font-size: 2em;
  margin-top: 20px;
}
</style>
