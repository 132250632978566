<template>
  <div class="base_layer wizard">
    <Loading
      :active.sync="isLoadingData"
      :is-full-page="false"
      :height="125"
      :width="125"
      backgroundColor="#6D717A"
      color="#7ABE12"
    ></Loading>

    <Header :text="t('student_identification.identification')" :destination-component="null" />

    <div ref="studyRecapScroll" class="d-flex justify-center align-start flex-column">
      <div class="in_middle">
        <input
          type="text"
          @focus="showKeyBoard"
          data-layout="compact"
          class="want_ticket_wide"
          :placeholder="t('student_identification.loginName')"
          v-model="xlogin"
        >
      </div>

      <div class="in_middle2">
        <div class="add_next">
          <span class="info">{{ t('student_identification.info') }}</span>
        </div>
      </div>

      <div class="generate_ticket_button">
        <button id="generate_ticket" :disabled="isGenerateTicketDisabled" @click="showLoad">
          {{ t('student_identification.generate') }}
        </button>
      </div>
    </div>

    <div class="myKeyboard">

      <Keyboard
        id="keyboard"
        :options="options"
        v-if="visible"
        :layout="layout"
        :accept="hideKeyBoard"
        :next="hideKeyBoard"
        :input="input"
      />
    </div>

    <div class="row_bottom">
      <div class="generate_ticket_button_right">
        <go-cancel-arrow />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import moment from "moment";
import Header from "@/components/Header.vue";
import GoCancelArrow from "@/components/GoCancelArrow.vue";
import Loading from 'vue3-loading-overlay';
import { Keyboard } from "vue3-touch-keyboard";
import "../assets/vue3-touch-keyboard-style.css";
import { restoreTimeMilis } from '@/store/config';
import emitter from "@/config/emitter";

const store = useStore();
const { t } = useI18n();

const xlogin = ref("");
const isLoadingData = ref(false);
const visible = ref(false);
const layout = ref("default");
const input = ref(null);
const timeout = ref(null);

const options = ref({
  useKbEvents: false,
  preventClickEvent: false
});

const isGenerateTicketDisabled = computed(() => xlogin.value.trim() === "");

const showKeyBoard = (e) => {
  input.value = e.target;
  layout.value = e.target.dataset.layout;
  visible.value = true;
};

const hideKeyBoard = () => {
  visible.value = false;
};

const send = async () => {
  try {
    store.dispatch('queueItemTo/setStartTime', moment().format("HH:mm").toString());
    store.dispatch('queueItemTo/setXname', xlogin.value);

    const studyCategoryId = store.getters["queueItemTo/getStudyCategoryId"];
    const isJustMeeting = studyCategoryId === null;

    // Student didn't chose any study typ, just meeting – used for Student mobilities
    if (isJustMeeting) {
      await store.dispatch('queueItemTo/sendQueueToMeeting');
      const defaultTimeForMeeting = 10;
      store.dispatch('queueItemTo/setEndTime', moment().add(defaultTimeForMeeting, "minutes").format("HH:mm").toString());
    }

    await store.dispatch('queueItemTo/sendQueueTo');
    await store.dispatch('queueItemTo/getImage');

    goToTicket();
  } catch (e) {
    if (e === 409) {
      isLoadingData.value = false;
    }
    emitter.emit('toError');
  }
};

const showLoad = async () => {
  isLoadingData.value = true;
  await send();
};

const goToTicket = () => {
  isLoadingData.value = false;
  emitter.emit('toTicket');
};

onMounted(() => {
  timeout.value = setTimeout(() => {
    emitter.emit('Start');
  }, restoreTimeMilis.restoreTime);
});

onBeforeUnmount(() => {
  clearTimeout(timeout.value);
});

</script>

<style scoped>
.in_middle {
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.in_middle2 {
  margin-top: 25px;
}

.want_ticket_wide {
  text-align: left;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 700px;
  height: 80px;
  background-color: white;
  border-radius: 40px;
  color: rgb(0, 0, 0);
  font-size: x-large;
  border: none;
  font-weight: bolder;
}


.info {
  width: 571px;
  margin-left: 15px;
  color: white;
  font-size: x-large;
}

#generate_ticket {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 470px;
  height: 100px;
  background: #7ABE12;
  border-radius: 40px;
  color: white;
  font-size: x-large;
  border: none;
  -webkit-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  -moz-box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  box-shadow: 0 0 11px 2px rgba(48, 47, 48, 0.87);
  font-weight: bolder;
  float: right;
}

#generate_ticket:active {
  background-color: white;
  color: black;
  transform: translateY(1px);
  filter: saturate(150%);
}

#generate_ticket:disabled {
  background-color: #a9a9ad;
  color: #727272;
}

.generate_ticket_button {
  float: left;
  position: absolute;
  left: 50%;
  bottom: 40%;
  transform: translateX(-50%);
}

.generate_ticket_button_right {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.5em;
  display: flex;
  justify-content: center;
}

.row_bottom {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
  padding: 0.5em;
  position: relative;
}

.add_next {
  margin: 0 auto;
  width: 620px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::placeholder{
  color: black;
}
.in_middle input{
  padding-left: 25px;
  color: black
}
.myKeyboard{
  margin-top: 25px;
}

#keyboard {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 300px;
  font-size: 20px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1em;

  background-color: #EEE;
  border-radius: 10px;
}
</style>
