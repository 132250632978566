import moment from "moment/moment";
import {restoreTimeMilis} from "@/store/config";
import store from "@/store/Store";

const state = {
    studyOpenHours: []
};

const getters = {
    getStudyOpenHours: state => state.studyOpenHours,

    getStudyOpenHour: state => dayNumber => {
        return state.studyOpenHours.find(studyOpenHour => studyOpenHour.dayNumber === dayNumber);
    },

    areStudyOpenHoursFilled: state => {
        return state.studyOpenHours.length !== 0;
    },

    isClosed: state => {
        let currentDayStudyHours = state.studyOpenHours.find(studyOpenHour => studyOpenHour.dayNumber === moment().day().toString());
        if (currentDayStudyHours !== undefined && currentDayStudyHours !== null) {
            let now = moment(moment(), "HH:mm");
            return (currentDayStudyHours.openTime === undefined && currentDayStudyHours.closeTime === undefined) ||
              (currentDayStudyHours.openTime === null && currentDayStudyHours.closeTime === null) ||
              (now.isSameOrBefore(moment(currentDayStudyHours.openTime, "HH:mm").subtract(restoreTimeMilis.startEndRerouteTime, "minute")) ||
                now.isSameOrAfter(moment(currentDayStudyHours.closeTime, "HH:mm").subtract(restoreTimeMilis.startEndRerouteTime, "minute")));
        }
        return true;
    },

    isPaused: state => {
      let currentDayStudyHours = state.studyOpenHours.find(studyOpenHour => studyOpenHour.dayNumber === moment().day().toString()).value;
      if (currentDayStudyHours !== undefined && currentDayStudyHours !== null && !store.getters.isClosed) {
        let now = moment(moment(), "HH:mm");
        return (currentDayStudyHours.pauseStart !== undefined && currentDayStudyHours.pauseEnd !== undefined) &&
          (currentDayStudyHours.pauseStart !== null && currentDayStudyHours.pauseEnd !== null) &&
          (now.isAfter(moment(currentDayStudyHours.pauseStart, "HH:mm").subtract(restoreTimeMilis.pauseRerouteTime, "minute")) &&
            now.isBefore(moment(currentDayStudyHours.pauseEnd, "HH:mm")));
      }
      return false;
    }
};

const actions = {

    resetStudyOpenHours({commit}) {
        commit('flushData');
    },

    setStudyOpenHours({commit}, data){
        commit('setStudyOpenHours',data)
    }
};

const mutations = {
    setStudyOpenHours: (state, studyOpenHours) => (state.studyOpenHours = studyOpenHours),

    flushData(state) {
        state.studyOpenHours = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
