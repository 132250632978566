export const fullURL = {
    url: process.env.VUE_APP_SERVER_URL
};

export const serviceAuthURL = {
    url: process.env.VUE_APP_AUTH_SERVER_URL + "/realms/" + process.env.VUE_APP_AUTH_REALM + "/protocol/openid-connect/token"
};

export const backend_urls = {
    login: "login",
    studyFields: "study_department/study_fields/",
    studyCategory: "study_department/study_problems_category/",
    studyRequirement: "study_department/study_problems_item/",
    queueItem: "queue/demand/",
    assistant: "study_department/assistant/",
    studyOpenHours: "study_department/open_hours/",
    studyRestrictions: "study_department/assistant/closed_hours/restrictions/",
    images: "study_department/images/",
    assistantBalance: "queue/demand/AssistantBalance/"
};

export const loginPass = {
    username: "study",
    password: "heslo",
};

export const restoreTimeMilis = {
    restoreTime: 120000, // fallback pre obyč. stránku, za jak dlouho se vrati panel do vychoziho stavu
    restoreTimeTicket: 60000, // fallback pre ticket stránku, za jak dlouho se vrati panel do vychoziho stavu
    restoreTimeEnd: 3000, // fallback pre konečnú / end stránku s rozloucenim se, za jak dlouho se vrati panel do vychoziho stavu
    pauseRerouteTime: 0, // minutes to minutes to close the touch panel before the start of a pause
    startEndRerouteTime: 5, // minutes to open touch panel before the start of the open hours, minutes to close the touch panel before the end of open hours
    restoreTimeError: 6000, // fallback pre error, jak dlouho zobrazovat error hlasku pred resetovanim stavu
    changeLocaleTime: 3000, // čas na menenie jazykov, jak casto se strida cesky a anglicky popis u hlasek typu chyba
}
